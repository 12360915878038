import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform, ModalController } from '@ionic/angular';
import { ApiService } from '../shared/services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { DatePipe } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { interval } from 'rxjs';

@Component({
  selector: 'app-send',
  templateUrl: './send.html',
  styleUrls: ['./send.scss'],
  providers: [DatePipe],
  animations: [
    trigger(
      'SlideUp', [
        transition(':enter', [
          style({ transform: 'translatey(50%)', opacity: 0 }),
          animate('.5s ease-in-out', style({ transform: 'translatey(0%)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translatey(0%)', opacity: 1 }),
          animate('.5s ease-in-out', style({ transform: 'translatey(50%)', opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'Show', [
        transition(':enter', [
          style({ transform: 'translatey(-50%)', opacity: 0 }),
          animate('.5s ease-in-out', style({ transform: 'translatey(0%)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translatey(0%)', opacity: 1 }),
          animate('.5s ease-in-out', style({ transform: 'translatey(-50%)', opacity: 0 }))
        ])
      ]
    )
  ]
})
export class SendComponent implements OnInit, OnDestroy {

  page;
  files = [];
  recipients = [];
  data;
  body;
  showErrors;
  inbox;


  formData = new FormGroup({
    to: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    subject: new FormControl(''),
    text: new FormControl('')
  });

  @ViewChild('fileChooser') fileChooser: ElementRef;

  constructor(
    private platform: Platform,
    private events: Events,
    private router: Router,
    private modal: ModalController,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private storage: Storage
  ) {

    this.page = this.router.url.split("/")[3];
    registerLocaleData(localeRu, 'ru');;
  }

  ngOnInit() {
    //Получение тела письма если есть data
    let w8 = interval(100).subscribe(x => {
      if (this.data && this.data[0].id) {
        if (this.apiService.getAuth()) {
          w8.unsubscribe();

          let type = this.page;
          if (this.page == 'flag' || this.page == 'unread') {
            type = 'all'
          }

          this.storage.get(type).then((val) => {
            if (val) {

              if (val.filter(x => x.id == this.data[0].id)[0].body) {
                this.body = val.filter(x => x.id == this.data[0].id)[0].body;
                this.setFormData(this.body);
              }
              else {
                this.events.publish('presentLoading');
                let idArray = [];
                idArray.push(this.data[0].id);
                this.apiService.action('view', idArray)
                  .subscribe((Response) => {
                    if (!Response['error']) {
                      this.body = Response['result'][0];
                      this.setFormData(this.body);
                      this.apiService.setBody(type, this.body);
                      this.events.publish('stopLoading');
                    }

                  });
              }
            }
            else {
              this.router.navigate(['/mailer/list/'+this.page]);
            }
          });

        }
      }
    });

  }

  //Действие
  action(type) {
    //type: important/read/unread/Junk/Trash/delete
    let idArray = [];
    idArray.push(this.data[0].id);
    this.apiService.action(type, idArray)
      .subscribe((Response) => {
        if (Response['result'][0] == true) {
          this.events.publish('action', type, this.data[0].id);
        }
        else {
          this.events.publish('presentToast', 'Ошибка', '2000', 'danger');
        }
      });
  }

  //Заполнение полей
  setFormData(body) {
    if (!this.data[0].forward) {
      if (body.cc) {
        console.log(body.cc);
        Object.keys(body.cc)
          .forEach((key, index) => {
            this.recipients.push({ email: key });
          });
      }
      this.formData = new FormGroup({
        to: new FormControl(body.fromAddress, Validators.compose([Validators.required, Validators.email])),
        subject: new FormControl('RE: ' + body.subject),
        text: new FormControl('')
      });
    }
    else {
      this.formData = new FormGroup({
        to: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        subject: new FormControl('FW: ' + body.subject),
        text: new FormControl('')
      });
    }
  }

  //Загрузка файлов
  upload($event) {
    console.log($event.target.files);
    //this.files = $event.target.files;
    Array.from($event.target.files).forEach(item => {
      this.files.push(item)
    });

    // this.files.splice(0, 1);
    // $event.target.files.setValue(this.files);
    // console.log(this.files);
  }

  //Добавление приложенного файла
  addFile() {
    this.fileChooser.nativeElement.click();
  }

  //Удаление приложенного файла
  removeFile(file) {
    // let index = 0;
    // Array.from(this.files).forEach(item => {
    //   if(file==item) {
    //     console.log(item);
    //     Array.from(this.files).splice(index, 1);
    //   }
    //   index += 1;
    // });

    var newFileList = Array.from(this.files);
    const index: number = newFileList.indexOf(file);
    if (index !== -1) {
      newFileList.splice(index, 1);
    }
    this.files = newFileList;
  }



  //Добавление емейла для копии
  addRecipients() {
    this.recipients.push({ email: '' });
  }

  //Удаление емейла для копии
  removeRecipients(recipient) {
    const index: number = this.recipients.indexOf(recipient);
    if (index !== -1) {
      this.recipients.splice(index, 1);
    }
  }

  //Эвент ввода емейла для копии
  onInputRecipient(event, recipient) {
    recipient.email = event.target.value;
  }

  //Эвент валидации формы
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  //Отправка формы
  send() {
    this.validateAllFormFields(this.formData);
    if (!this.formData.invalid) {
      this.events.publish('presentLoading');
      let cc = [];
      if (this.recipients.length > 0) {
        this.recipients.forEach(recipient => {
          cc.push(recipient.email);
        })
      }

      let text = ' ';
      if (this.formData.value.text) {
        text = this.formData.value.text.trim();
      }
      if (this.body) {
        // let whom = '';
        // Object.keys(this.body.to)
        //   .forEach(function(key, index) {
        //     whom = whom + key + '; ';
        //   });

        // let date = this.datePipe.transform(this.body.date, 'longDate') + ' в ' + this.datePipe.transform(this.body.date, 'shortTime');

        text = text + '<br><br><br><div style="mso-element:para-border-div;border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0cm 0cm 0cm">' + this.body.date + ', ' + this.body.fromName + ' <<a href="mailto:' + this.body.fromAddress + '">' + this.body.fromAddress + '</a>> <br><br>' + this.body.textHtml.trim() + '<div>';

        // text = text + '<br><br><div class="reText">От: ' + this.body.fromAddress + ' <' + this.body.fromName + '> <br>Отправлено: ' + date + '<br>Кому: ' + whom + '<br>Тема: ' + this.body.subject + '<br><br>' + this.body.textHtml.trim() + '<div>';
      }
      let subject = ' ';
      if (this.formData.value.subject) {
        subject = this.formData.value.subject.trim();
      }


      this.apiService.msgSend(this.formData.value.to, cc, subject, text, this.files)
        .subscribe((Response) => {
          console.log('Response', Response);
          this.events.publish('stopLoading');
          if (Response['result'] == true) {

            this.closeModal();
            if (this.body) {
              this.router.navigate(['/mailer/list/'+this.page]);
            }
          }
          else {
            this.events.publish('presentToast', 'Ошибка, Что-то пошло не так!', '2000', 'danger');
            console.log(Response);
          }
        });
    }
    else {
      this.showErrors = true;
    }
  }

  //Закрытие модального окна
  closeModal() {
    this.modal.dismiss();
    this.events.publish('stopLoading');
  }

  ngOnDestroy(){

  }

}
