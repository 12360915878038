import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Events } from '@ionic/angular';

@Component({
    template: `
    <ion-list no-margin *ngFor="let p of appPages">
    <ion-item class="p-2" lines="none" (click)="publish(p)" [routerLink]="[p.url]" routerDirection="root">
      <ion-icon slot="start" color="secondary" [name]="p.icon"></ion-icon>
      <ion-label>
        {{ p.title }}
      </ion-label>
    </ion-item>
    </ion-list>
    `
})
export class PopoverComponent {

    constructor(
      private events: Events,
      private popoverController: PopoverController
    ) {}


  event;

    //Кнопки меню
    appPages = [
      {
        title: 'Входящие',
        url: '/mailer/list/all',
        page: 'all',
        icon: 'filing'
      },
      {
        title: 'Исходящие',
        url: '/mailer/list/sent',
        page: 'sent',
        icon: 'mail-open'
      },
      {
        title: 'Важные',
        url: '/mailer/list/flag',
        page: 'flag',
        icon: 'flame'
      },
      {
        title: 'Не прочитаные',
        url: '/mailer/list/unread',
        page: 'unread',
        icon: 'mail-unread'
      },
      {
        title: 'Спам',
        url: '/mailer/list/junk',
        page: 'junk',
        icon: 'close-circle-outline'
      },
      {
        title: 'Корзина',
        url: '/mailer/list/trash',
        page: 'trash',
        icon: 'trash'
      }
    ];

  publish(p){
    this.popoverController.dismiss('');

  }
}
