import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Events } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Md5 } from 'ts-md5/dist/md5';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent, throwError } from 'rxjs';
import { catchError, retry, map, filter, scan } from 'rxjs/operators';

@Injectable()
export class ApiService {

  httpOptionsJson: any = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      'Content-Type': 'application/json'
    })
  }
  httpOptionsQuery: any = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  token: string;
  userData: any;
  authDone: boolean;

  inbox;

  private handleError(error: HttpErrorResponse) {
    return throwError('-_-');
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private events: Events,
    private httpClient: HttpClient,
    private storage: Storage
  ) {

  }

  //Получение Токена
  getToken(login, password) {
    const md5 = new Md5();
    let password_hash = md5.appendStr(password).end();
    let data = 'login=' + login + '&password_hash=' + password_hash;

    this.httpClient
      .post('https://api.oneclick.ru:1443/api/user/gettoken', data, this.httpOptionsQuery)
      .pipe(catchError(this.handleError))
      .subscribe((Response) => {
        //console.log('gettoken', Response);
        if (Response['result'] == 'ok') {
          this.token = Response['token'];
          this.storage.set('token', this.token);
          this.storage.set('login', login);
          this.storage.set('password', password);
          this.setUserData(this.token);
        }
      });
  }

  //Проверка Токена
  checkToken(token) {
    this.httpClient
      .post('https://api.oneclick.ru:1443/api/user/checktoken', 'token=' + token, this.httpOptionsQuery)
      .pipe(catchError(this.handleError))
      .subscribe((Response) => {
        if (Response['result'] == 'токен корректный') {
          this.token = token;
          this.setUserData(token);
        }
        else {
          //редирект на авторизацию
          this.getToken('anking@mail.com', '12345') // временно
        }
      });
  }

  //Получение данных пользователя
  setUserData(token) {
    //console.log('token', token)
    this.httpClient
      .post('https://api.oneclick.ru:1443/api/user/item', 'token=' + token, this.httpOptionsQuery)
      .pipe(catchError(this.handleError))
      .subscribe((Response) => {
        console.log(Response);
        this.userData = Response['user'];
        if (Response['result'] == 'ok') {
          this.storage.get('login').then((val) => {
            if (val != null) {
              this.userData['login'] = val;
            }
          });
          this.storage.get('password').then((val) => {
            if (val != null) {
              this.userData['password'] = val;
            }
          });
          this.storage.get('username').then((val) => {
            if (val != null) {
              this.userData['username'] = val;
              this.authDone = true;
            }
            else {
              this.userData['username'] = 'anking@oneclick.ru';
              this.authDone = true;
            } //Временно
          });
        }
        else {
          //редирект на авторизацию
        }
      });
  }

  //Проверка аутификации
  getAuth() {
    return this.authDone;
  }

  //Регистрация почты
  register(username) {
    let data = { username: username, password: this.userData['password'], token: this.token, name: this.userData['name'], id: this.userData['id'] };

    return this.httpClient
      .post('https://oneclick.teo-crm.ru/api/list/register', data, this.httpOptionsJson)
      .pipe(catchError(this.handleError))
  }

  //Запись списка почты
  setInbox(page, inbox, event) {
    this.storage.set(page, inbox);
  }


  //Запись тела письма
  setBody(page, body) {
    this.storage.get(page).then((val) => {
      if (val) {
        if (val.filter(x => x.id == body.id)) {
          val.filter(x => x.id == body.id)[0].body = body;
        }
        this.storage.set(page, val);
      }
    });

  }


  //Поиск писем
  search(type, value, start, end) {
  if(type=='sent') { type = 'Sent';}
  if(type=='junk') { type = 'Junk';}
  if(type=='trash') { type = 'Trash';}
    let data = { login: this.userData['username'], password: this.userData['password'], type: type, value: value, start: start, end: end };
    let request = 'request3';
    if(type=='date' || type=='all' || type=='flag' || type=='unread') {
      request = 'request';
    }

    return this.httpClient
      .post('https://oneclick.teo-crm.ru/api/list/'+request, data, this.httpOptionsJson)
      .pipe(catchError(this.handleError))

  }


  //Отправка сообщения
  msgSend(to, cc, subject, text, files) {
    let formData = new FormData();
    formData.append('login', this.userData['username']);
    formData.append('password', this.userData['password']);
    formData.append('cc', cc);
    formData.append('to', to);
    formData.append('subject', subject);
    //var blob = new Blob([text], { type: "text/xml"});
    formData.append("text", text);
    for (let i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]);
    }
    return this.httpClient
      .post('https://oneclick.teo-crm.ru/api/list/send', formData)
      .pipe(catchError(this.handleError))
  }

  //Удаление письма
  deleteMail(id) {
    return this.httpClient
      .post('https://mailer.oneclick.ru:11443/api/list/delete', 'login=' + this.userData['username'] + '&password=' + this.userData['password'] + '&id=' + id, this.httpOptionsJson)
      .pipe(catchError(this.handleError))
  }

  //Действие с письмом
  action(type, id) {
    let value = '';
    if (type == 'Junk' || type == 'Trash' || type == 'inbox') {
      if(type=='inbox') { type = 'INBOX';}
      value = type;
      type = 'move';
    }
    let data = { login: this.userData['username'], password: this.userData['password'], type: type, id: id, value: value };

    return this.httpClient
      .post('https://oneclick.teo-crm.ru/api/list/action', data, this.httpOptionsJson)
      .pipe(catchError(this.handleError))
  }


}
