import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform, AlertController, ModalController, LoadingController, ToastController, PopoverController, MenuController } from '@ionic/angular';
import { PopoverComponent } from './mailer/popover.component';
import { SendComponent } from './mailer/send/send.component';
import { Storage } from '@ionic/storage';
import { ApiService } from './mailer/shared/services/api.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  loading;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private events: Events,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private modal: ModalController,
    private popoverController: PopoverController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  //Закрытие меню
  closeMenu() {
    this.menu.close();
  }

  //Запуск приложения
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {

    //Проверка токена
    this.storage.get('token').then((val) => {
      if (val != null) {
        this.apiService.checkToken(val);
      }
      else {
        //Временно
        this.apiService.getToken('anking@mail.com', '12345')
        // this.router.navigate(['/mailer/register']);
        // this.events.publish('stopLoading');
      }
    });

    //Эвент модального окна отправки сообщения
    this.events.subscribe('openModal', (data) => {
      this.openModal(data);
    });

    //Эвент открытия всплывающего меню
    this.events.subscribe('presentPopover', (event) => {
      this.presentPopover(event);
    });

    //Эвент запуска анимации загрузки
    this.events.subscribe('presentLoading', () => {
      this.presentLoading();
    });
    //Эвент остановки анимации закгрузки
    this.events.subscribe('stopLoading', () => {
      setTimeout(() => {
        if (this.loading) {
          this.loading.dismiss();
        }
      }, 1);
    });
    //Эвент открытия окна предупреждения
    this.events.subscribe('presentAlert', (header, subHeader, message) => {
      this.presentAlert(header, subHeader, message);
    });
    //Эвент появления уведомления
    this.events.subscribe('presentToast', (message, time, color) => {
      this.presentToast(message, time, color);
    });
  }

  //Модальное окно
  async openModal(data) {
    const modal = await this.modal.create({
      component: SendComponent,
      componentProps: { data }
    });
    return await modal.present();
  }

  //Загрузка
  async presentLoading() {
    this.loading = await this.loadingController.create({
      spinner: 'dots',
    });
    await this.loading.present();
  }

  //Окно предупредждения
  async presentAlert(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  //Уведомление
  async presentToast(message, time, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: time,
      color: color,
      position: 'bottom',
      cssClass: 'text-center',
      translucent: true
    });
    toast.present();
  }

  //Всплывающее меню
  async presentPopover(event: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: event,
      translucent: true
    });
    return await popover.present();
  }

}
