import { IonicModule } from '@ionic/angular';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { SanitizeHtmlPipe } from '../shared/pipes/sanitize-html.pipe';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutosizeModule,
  ],
  declarations: [SanitizeHtmlPipe],
  entryComponents: [],
  exports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutosizeModule,
    SanitizeHtmlPipe
  ]
})
export class SharedModule { }
